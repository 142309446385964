<template>
  <div class="user-center">
    <navbar :showBack="false" bg="transparent" />
    <div class="user-info">
      <div class="left">
        <div class="avatar" @click="countClick">
          <img
            class="avatar-img"
            :src="userInfo.avatar || userInfo.avatarImg"
            alt=""
          />
          <img class="sex" :src="userInfo.sexImg" alt="" />
        </div>
        <div class="info">
          <div class="nickname">{{ userInfo.real_name }}</div>
          <div class="mobile">{{ userInfo.mobile }}</div>
        </div>
      </div>
      <router-link to="profile">
        <div class="edit-profile-btn">
          修改资料
        </div>
      </router-link>
    </div>
    <div class="vip-card">
      <div class="vip-info">
        <div class="vip-level">
          {{ userInfo.vip_level > 0 ? userVip.levelname : "普通会员" }}
        </div>
        <div v-if="userInfo.vip_expire_time > 0" class="vip-expire-time">
          有效期至 {{ formatTime(userInfo.vip_expire_time * 1000) }}
        </div>
      </div>
      <div class="vip-btn" @click="$refs['pay-dialog'].show()">
        {{ userInfo.vip_level > 0 ? "立即续费" : "立即开通" }}
      </div>
    </div>
    <div class="my-func-list">
      <!-- <template v-if="userInfo.vip_level > 0"> -->
      <template>
        <router-link class="func-list-item" to="benefit">
          <img class="icon" src="@/assets/h5images/user/shouyi.png" alt="" />
          <div class="func-name">我的收益</div>
        </router-link>
        <router-link class="func-list-item" to="teams">
          <img class="icon" src="@/assets/h5images/user/team.png" alt="" />
          <div class="func-name">我的团队成员</div>
        </router-link>
        <router-link class="func-list-item" to="my-pushu">
          <img class="icon" src="@/assets/h5images/user/upload.png" alt="" />
          <div class="func-name">上传谱书</div>
        </router-link>
      </template>
      <router-link class="func-list-item" to="watched">
        <img class="icon" src="@/assets/h5images/user/watched.png" alt="" />
        <div class="func-name">已观看收费谱书</div>
      </router-link>
      <router-link class="func-list-item" to="my-events">
        <img class="icon" src="@/assets/h5images/user/publish.png" alt="" />
        <div class="func-name">我的发布</div>
      </router-link>
      <router-link class="func-list-item" to="my-qrcode">
        <img class="icon" src="@/assets/h5images/user/watched.png" alt="" />
        <div class="func-name">分享码</div>
      </router-link>
      <div class="func-list-item" @click="logout">
        <img class="icon" src="@/assets/h5images/user/watched.png" alt="" />
        <div class="func-name">退出登陆</div>
      </div>
    </div>
    <pay-dialog ref="pay-dialog" @success="fetchUserInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dayjs from "dayjs";
import PayDialog from "@/components/h5/PayDialog";
import { Dialog } from "vant";
import Navbar from "@/components/h5/Navbar.vue";

export default {
  name: "UserCenter",
  components: {
    PayDialog,
    Navbar
  },
  data() {
    return {
      counter: 0
    };
  },
  computed: {
    ...mapState(["userInfo", "vipIndex"]),
    userVip() {
      return (
        this.vipIndex.find(item => item.id === this.userInfo.vip_level) || {}
      );
    }
  },
  methods: {
    ...mapActions(["clearUserInfo", "fetchUserInfo"]),
    formatTime(time, format = "YYYY-MM-DD") {
      return dayjs(time).format(format);
    },
    logout() {
      Dialog.confirm({
        title: "提示",
        message: "确认退出登陆吗?"
      }).then(() => {
        this.clearUserInfo();
        setTimeout(() => {
          location.reload();
        }, 500);
        // this.$router.replace("/h5/home");
      });
    },
    countClick() {
      clearTimeout(this.countTimer);
      this.countTimer = setTimeout(() => {
        this.counter = 0;
      }, 1000);
      console.log(this.counter);
      if (this.counter > 10) {
        window.localStorage.setItem("enable_vconsole", true);
        alert("开启vconsole成功，请刷新页面");
      }
      this.counter += 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-center {
  background-color: #fceaeb;
  .user-info {
    flex: none;
    display: flex;
    align-items: center;
    padding: 0 40px;
    height: 300px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-flow: row nowrap;

    .left {
      display: flex;
      align-items: center;
    }

    .avatar {
      width: 180px;
      height: 180px;
      // background: #7de060;
      border: 6px solid #ffffff;
      border-radius: 50%;
      position: relative;

      .avatar-img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .sex {
        height: 60px;
        width: 60px;
        position: absolute;
        right: 14px;
        bottom: -6px;
      }
    }

    .info {
      margin-left: 44px;

      .nickname {
        height: 48px;
        font-size: 56px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .mobile {
        margin-top: 40px;
        height: 29px;
        font-size: 44px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }

    .edit-profile-btn {
      width: 300px;
      height: 100px;
      border: 1px solid #ca0a15;
      border-radius: 50px;
      text-align: center;
      line-height: 100px;
      font-size: 36px;
      color: #ca0a15;
    }
  }

  .vip-card {
    width: 1045px;
    height: 200px;
    background: #ca0a15;
    border-radius: 10px 10px 0px 0px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;

    .vip-info {
      .vip-level {
        height: 47px;
        font-size: 56px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .vip-expire-time {
        height: 30px;
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 30px;
      }
    }

    .vip-btn {
      width: 300px;
      height: 100px;
      background: #fdf1e8;
      border-radius: 50px;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ca0a15;
      line-height: 100px;
      text-align: center;
    }
  }

  .my-func-list {
    width: 1125px;
    // height: 1742px;
    /* prettier-ignore */
    // height: calc(100vh - 46PX - 500px - 50PX);
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    padding: 40px;
    box-sizing: border-box;

    .func-list-item {
      height: 180px;
      width: 100%;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;

      // &:last-of-type {
      //   border-bottom: none;
      // }

      .icon {
        height: 100px;
        width: 100px;
      }

      .func-name {
        margin-left: 33px;
        font-size: 56px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }

    .qrcode {
      height: 400px;
      width: 400px;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
